import React from 'react'
import { Box, Flex } from 'reflexbox/styled-components'
import { graphql } from 'gatsby'
import {
  PageWrap,
  PageRow,
  PageHead,
  PageCopy,
  PageExpand,
} from '../components/global'
import SEO from '../components/seo'
import Img from 'gatsby-image'

import Image1 from '../images/progress/jhdixon-2019-06-29-fuji-gilgandra-4435.jpg'
import styled from 'styled-components'
import ArrowRight from '../svg/arrow-right'

// import POST_BUILD_IMAGES from '../data/approach-post-build.json'

export default ({ data }) => {
  let approachImage
  if (data.approachImage) {
    approachImage = (
      <Img fluid={data.approachImage.childImageSharp.fluid} alt="" />
    )
  }

  return (
    <PageWrap>
      <SEO
        title="Work with us"
        description="We're looking for a Carpenter to join our team building custom
        homes across inner Brisbane."
        image={Image1}
      />
      <PageRow>
        <PageHead>
          Work
          <br />
          With Us
        </PageHead>
        <Flex>
          <Box
            ml={[0, `${(2 / 10) * 100}%`]}
            width={[1, `${(6 / 10) * 100}%`]}
            px={[2, 3]}
            pt={[3, 4]}
            pb={[0, 0]}
          >
            <PageCopy>
              <p>
                We're looking for a Carpenter to join our team building custom
                homes across inner Brisbane. Send your CV or a bit about
                yourself to info@jhdixon.com.au
              </p>
            <Box>
              <StyledButton href="mailto:info@jhdixon.com.au">
                <span>Email</span>
                <ArrowRight />
              </StyledButton>
              </Box>
            </PageCopy>
          </Box>
        </Flex>
      </PageRow>
      <PageRow>
        <Flex flexWrap={[`wrap`]} px={[2, 3]} pb={[3, 0]}>
          <Box width={[1]}>{approachImage}</Box>
        </Flex>
      </PageRow>
    </PageWrap>
  )
}

const StyledButton = styled.a`
  font-weight: normal;
  height: auto;
  width: fit-content;

  border-radius: 0;
  padding: 1rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.fontSizing(theme.font.buttonSizes)};

  &,* {
    color: white !important;
    text-decoration: none;
  }


  svg {
    width: 1.5rem;
    margin-left: 2rem;
  }
`

export const query = graphql`
  query {
    approachImage: file(
      relativePath: {
        eq: "sections/jhdixon-2019-06-29-fuji-gilgandra-4435.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
